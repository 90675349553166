export const appConstants = {
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
  SET_IS_PROCESSING: 'SET_IS_PROCESSING',

  GET_CONTRACT_STATUS: 'GET_CONTRACT_STATUS',

  CONTRACT_STATUS_REQUEST: 'CONTRACT_STATUS_REQUEST',
  CONTRACT_STATUS_SUCCESS: 'CONTRACT_STATUS_SUCCESS',
  CONTRACT_STATUS_FAILURE: 'CONTRACT_STATUS_FAILURE',

  FEE_REQUEST: 'FEE_RATE_REQUEST',
  FEE_SUCCESS: 'FEE_RATE_SUCCESS',
  FEE_FAILURE: 'FEE_RATE_FAILURE',

  REFERRAL_REQUEST: 'REFERRAL_REQUEST',
  REFERRAL_SUCCESS: 'REFERRAL_SUCCESS',
  REFERRAL_FAILURE: 'REFERRAL_FAILURE',

  WALLET_CHAIN_REQUEST: 'WALLET_CHAIN_REQUEST',
  WALLET_CHAIN_SUCCESS: 'WALLET_CHAIN_SUCCESS',
  WALLET_CHAIN_FAILURE: 'WALLET_CHAIN_FAILURE',

  CONNECT_RESET: 'CONTRACT_INFO_RESET_ON_WALLET_CONNECTED',
}
