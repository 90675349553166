import { storeItem, loadStoredItem, clearStoredItem } from 'common/utils/storage'
import { TRADE_STATUS, GRAPHQL_LISTS, GRAPHQL_RECENT_LISTS } from 'EthereumApp/app-constants'
import { isInvolved, isSeller, isBuyer, storedTradeObject } from 'EthereumApp/utils'
import { tradesConstants } from '../constants'

const _ = require('underscore')

// recent transfers that have activities
const initialState = {
  // store trades that current user is involved in (seller, buyer)
  allLists: loadStoredItem('@allLists'),

  // public recent lists, not stored in cache
  recentLists: {},
  // loading list names
  loadingRecentLists: [],
  // list name => error
  loadRecentListsError: {},

  // loading list names
  loadingLists: [],
  // list name => load error
  loadListsError: {},

  // trade ids that are being reloaded
  // { tradeId => true|false|undefined }
  loadingTradeIds: null,
  // { tradeId => error }
  loadingTradeErrors: null,
  error: null
}

// make sure there's no null lists
const prepareAllLists = (stateAllLists) => {
  const allLists = {...stateAllLists}
  Object.values(GRAPHQL_LISTS).forEach(listName => {
    if (!allLists[listName]) allLists[listName] = []
  })

  if (!allLists.cachedTrades) allLists.cachedTrades = {}

  return allLists
}

// remove one trade id from all possible stored lists
function removeFromAllList (stateAllLists, tradeId) {
  const allLists = {...stateAllLists}
  Object.values(GRAPHQL_LISTS).forEach(listName => {
    allLists[listName] = _.without(allLists[listName], tradeId)
  })

  return allLists
}

export function trades(state = initialState, action) {
  switch (action.type) {

    // request to fetch 1 on-chain trade
    case tradesConstants.GET_ONE_REQUEST: {
      return {
        ...state,
        loadingTradeIds: {...state.loadingTradeIds, [action.tradeId]: true}
      }
    }

    // one trade is fetched
    case tradesConstants.STORE_ONE_FROM_RECEIPT:
    case tradesConstants.GET_ONE_SUCCESS: {
      let allLists = prepareAllLists(state.allLists)
      const storedTrade = action.storedTrade ? action.storedTrade : storedTradeObject(action.trade, action.onChain)

      // store this tradeId only when user is buyer or seller
      if (isInvolved(storedTrade, action.user)) {
        // remove tradeId from all lists
        allLists = removeFromAllList(allLists, storedTrade.tradeId)

        // save tradeId back to it's corresponding list
        const status = parseInt(storedTrade.status)
        switch (status) {
          case TRADE_STATUS.LISTED: {
            if (isBuyer(storedTrade, action.user)) {
              // for-you trade, insert at top
              allLists[GRAPHQL_LISTS.FOR_YOU].splice(0, 0, storedTrade.tradeId)
            }
            else
              // user listed trade, insert at top
              allLists[GRAPHQL_LISTS.LISTED].splice(0, 0, storedTrade.tradeId)
            break
          }

          case TRADE_STATUS.CANCELLED: {
            allLists[GRAPHQL_LISTS.CANCELLED].splice(0, 0, storedTrade.tradeId)
            break
          }

          case TRADE_STATUS.COMPLETED: {
            if (isSeller(storedTrade, action.user))
              allLists[GRAPHQL_LISTS.SOLD].splice(0, 0, storedTrade.tradeId)
            else
              allLists[GRAPHQL_LISTS.BOUGHT].splice(0, 0, storedTrade.tradeId)

            break
          }

          default:
            break
        }

        // update cachedTrades
        allLists.cachedTrades[storedTrade.tradeId] = storedTrade
        storeItem('@allLists', allLists)
      }

      return {
        ...state,
        loadingTradeIds: _.omit(state.loadingTradeIds, storedTrade.tradeId),
        loadingTradeErrors: _.omit(state.loadingTradeErrors, storedTrade.tradeId),
        allLists: allLists,
      }
    }

    // mark trade notified
    case tradesConstants.TRADE_NOTIFIED: {
      let allLists = prepareAllLists(state.allLists)

      const cachedTrade = allLists.cachedTrades?.[action.tradeId]
      if (cachedTrade)
        cachedTrade.userNotifiedAt = cachedTrade.updatedAt

      storeItem('@allLists', allLists)

      return {
        ...state,
        allLists: allLists
      }
    }

    // clear stored trade
    case tradesConstants.CLEAR_STORED_TRADE: {
      let allLists = prepareAllLists(state.allLists)
      delete allLists.cachedTrades[action.tradeId]
      storeItem('@allLists', allLists)

      return {
        ...state,
        allLists: allLists
      }
    }

    // clear stored trade
    case tradesConstants.REMOVE_STORED_TRADE: {
      let allLists = prepareAllLists(state.allLists)
      delete allLists.cachedTrades[action.tradeId]
      allLists = removeFromAllList(allLists, action.tradeId)

      storeItem('@allLists', allLists)

      return {
        ...state,
        allLists: allLists
      }
    }

    // fail to fetch 1 on-chain trade
    case tradesConstants.GET_ONE_FAILURE: {
      return {
        ...state,
        loadingTradeIds: _.omit(state.loadingTradeIds, action.tradeId),
        loadingTradeErrors: {...state.loadingTradeErrors, [action.tradeId]: action.error},
      }
    }

    case tradesConstants.CLEAR_LOAD_TRADE_ERROR: {
      return {
        ...state,
        loadingTradeErrors: _.omit(state.loadingTradeErrors, action.tradeId),
      }
    }

    // request to fetch 1 list
    case tradesConstants.GET_LIST_REQUEST: {
      const loadingLists = [...state.loadingLists]
      if (!loadingLists.includes(action.listName))
        loadingLists.push(action.listName)

      return {
        ...state,
        loadingLists: loadingLists,
        loadListsError: _.omit(state.loadListsError, action.listName)
      }
    }

    // fetch 1 list successfully
    case tradesConstants.GET_LIST_SUCCESS: {
      const allLists = prepareAllLists(state.allLists)

      // update allLists & cachedTrades
      action.trades.forEach(t => {
        allLists[action.listName].push(t.tradeId)
        allLists.cachedTrades[t.tradeId] = t
      })
      storeItem('@allLists', allLists)

      return {
        ...state,
        allLists: allLists,
        loadingLists: _.without(state.loadingLists, action.listName),
        loadListsError: _.omit(state.loadListsError, action.listName)
      }
    }

    // failed to fetch 1 list
    case tradesConstants.GET_LIST_FAILURE: {
      return {
        ...state,
        loadingLists: _.without(state.loadingLists, action.listName),
        loadListsError: {...state.loadListsError, [action.listName]: action.error}
      }
    }

    // request to fetch list of trades
    case tradesConstants.GET_LISTS_REQUEST: {
      return {
        ...state,
        loadingLists: _.values(GRAPHQL_LISTS),
        loadListsError: {},
      }
    }

    // all lists are fetched successfully
    case tradesConstants.GET_LISTS_SUCCESS: {
      const allLists = action.allLists
      const cachedTrades = {}

      // build id lists & store trade inside cachedTrades
      const storedLists = {}
      Object.keys(allLists).forEach(listKey => {
        storedLists[listKey] = allLists?.[listKey].map(t => {
          cachedTrades[t.tradeId] = storedTradeObject(t)
          return t.tradeId
        })
      })

      // trade reference by id
      storedLists.cachedTrades = cachedTrades

      // session store
      storeItem('@allLists', storedLists)

      return {
        ...state,
        loadingLists: [],
        loadListsError: {},
        allLists: storedLists,
      }
    }

    // fail to fetch list of trades
    case tradesConstants.GET_LISTS_FAILURE: {
      const loadingErrors = {}
      _.values(GRAPHQL_LISTS).forEach(listName => {
        loadingErrors[listName] = action.error
      })

      return {
        ...state,
        loadingLists: [],
        loadListsError: loadingErrors,
      }
    }

    // request to fetch 1 recent list
    case tradesConstants.GET_RECENT_LIST_REQUEST: {
      const loadingLists = [...state.loadingRecentLists]
      if (!loadingLists.includes(action.listName))
        loadingLists.push(action.listName)

      return {
        ...state,
        loadingRecentLists: loadingLists,
        loadRecentListsError: _.omit(state.loadRecentListsError, action.listName)
      }
    }

    // fetch 1 recent list successfully
    case tradesConstants.GET_RECENT_LIST_SUCCESS: {
      const recentLists = {...state.recentLists}
      recentLists[action.listName] = recentLists[action.listName].concat(action.trades)

      return {
        ...state,
        loadingRecentLists: _.without(state.loadingRecentLists, action.listName),
        loadRecentListsError: _.omit(state.loadRecentListsError, action.listName),
        recentLists: recentLists,
      }
    }

    // failed to fetch 1 recent list
    case tradesConstants.GET_RECENT_LIST_FAILURE: {
      return {
        ...state,
        loadingRecentLists: _.without(state.loadingRecentLists, action.listName),
        loadRecentListsError: {...state.loadRecentListsError, [action.listName]: action.error}
      }
    }

    // request to fetch public recent lists
    case tradesConstants.GET_RECENT_LISTS_REQUEST: {
      return {
        ...state,
        loadingRecentLists: _.values(GRAPHQL_RECENT_LISTS),
        loadRecentListsError: {},
      }
    }

    // recent lists loaded successfully
    case tradesConstants.GET_RECENT_LISTS_SUCCESS: {
      const recentLists = action.recentLists

      return {
        ...state,
        loadingRecentLists: [],
        loadRecentListsError: {},
        recentLists: recentLists,
      }
    }

    // recent lists failed to load
    case tradesConstants.GET_RECENT_LISTS_FAILURE: {
      const loadingErrors = {}
      _.values(GRAPHQL_RECENT_LISTS).forEach(listName => {
        loadingErrors[listName] = action.error
      })

      return {
        ...state,
        loadingRecentLists: [],
        loadRecentListsError: loadingErrors,
      }
    }

    // request to update recent list
    case tradesConstants.UPDATE_RECENT_LISTS_REQUEST: {
      return {
        ...state,
        loadingRecentLists: _.values(GRAPHQL_RECENT_LISTS),
      }
    }

    // update recent lists
    case tradesConstants.UPDATE_RECENT_LISTS: {
      const recentLists = {...state.recentLists}

      // init null list
      Object.values(GRAPHQL_RECENT_LISTS).forEach(key => {
        if (!recentLists?.[key])
          recentLists[key] = []
      })

      const trade = action.trade
      const pos = recentLists[GRAPHQL_RECENT_LISTS.LISTED].findIndex(t => t.tradeId.toLowerCase() === trade.tradeId.toLowerCase())

      switch (parseInt(trade.status)) {
        // add to listed trades
        case TRADE_STATUS.LISTED: {
          if (pos === -1)
            recentLists[GRAPHQL_RECENT_LISTS.LISTED].splice(0, 0, trade)
          break
        }

        // remove from listed trades
        case TRADE_STATUS.CANCELLED: {
          if (pos > -1)
            recentLists[GRAPHQL_RECENT_LISTS.LISTED].splice(pos, 1)
          break
        }

        // move from listed trades to completed trades
        case TRADE_STATUS.COMPLETED: {
          if (pos > -1)
            recentLists[GRAPHQL_RECENT_LISTS.LISTED].splice(pos, 1)

          const pos1 = recentLists[GRAPHQL_RECENT_LISTS.SOLD].findIndex(t => t.tradeId.toLowerCase() === trade.tradeId.toLowerCase())
          if (pos1 === -1)
            recentLists[GRAPHQL_RECENT_LISTS.SOLD].splice(0, 0, trade)

          break
        }

        default:
          break
      }

      return {
        ...state,
        loadingRecentLists: [],
        loadRecentListsError: {},
        recentLists: recentLists,
      }
    }

    // reset all stored trades when new account is connected
    case tradesConstants.CONNECT_RESET: {
      clearStoredItem('@allLists')

      const nullState = {...initialState}
      Object.keys(nullState).forEach(k => nullState[k] = null)

      return nullState
    }

    //
    default:
      return state
  }
}
