export const storeItem = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data))
}

export const loadStoredItem = (key) => {
  const storedData = sessionStorage.getItem(key)
  return storedData ? JSON.parse(storedData) : null
}

export const clearStoredItem = (key) => {
  sessionStorage.removeItem(key)
}

export const storeLocalItem = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const loadLocalStore = (key) => {
  const storedData = localStorage.getItem(key)
  return storedData ? JSON.parse(storedData) : null
}

export const clearLocalStoredItem = (key) => {
  localStorage.removeItem(key)
}

export const allStorageClear = () => {
  sessionStorage.clear()
  localStorage.clear()
}
