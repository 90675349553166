const Web3 = require('web3')
const web3 = new Web3()
const BigNumber = require('bignumber.js')

// wei to ether
export const fromWei = (weiStr) => {
	return web3.utils.fromWei(weiStr)
}

// ether to wei
export const toWei = (number) => {
	return web3.utils.toWei(number)
}

// is valid ETH address?
export const isAddress = (address) => {
	return web3.utils.isAddress(address)
}

// convert address to checksum value
export const toChecksum = (address) => {
	return web3.utils.toChecksumAddress(address)
}

// show bignumber string
export const showBigNumberValue = (number) => {
	if (!number) return
	const val = new BigNumber(number)
	return val.toFixed()
}

// same addresses?
// compare ethereum addresses
export const sameAddresses = (add0, add1) => {
  if (!add0 || !add1) return false
  return add0.toLowerCase() === add1.toLowerCase()
}
