export const userConstants = {
  CONNECTED: 'ACCOUNT_CONNECTED',
  CONNECT_RESET: 'USER_RESET_ON_WALLET_CONNECTED',

  REFERRER_REQUEST: 'REFERRER_REQUEST',
  REFERRER_SUCCESS: 'REFERRER_SUCCESS',
  REFERRER_FAILURE: 'REFERRER_FAILURE',

  STORE_REFERRER_PARAM: 'STORE_REFERRER_PARAM',

  INVITES_COUNT_REQUEST: 'INVITES_COUNT_REQUEST',
  INVITES_COUNT_SUCCESS: 'INVITES_COUNT_SUCCESS',
  INVITES_COUNT_FAILURE: 'INVITES_COUNT_FAILURE',

  TOTAL_TRADES_COUNT_REQUEST: 'TOTAL_TRADES_COUNT_REQUEST',
  TOTAL_TRADES_COUNT_SUCCESS: 'TOTAL_TRADES_COUNT_SUCCESS',
  TOTAL_TRADES_COUNT_FAILURE: 'TOTAL_TRADES_COUNT_FAILURE',

  SOLD_TRADES_COUNT_REQUEST: 'SOLD_TRADES_COUNT_REQUEST',
  SOLD_TRADES_COUNT_SUCCESS: 'SOLD_TRADES_COUNT_SUCCESS',
  SOLD_TRADES_COUNT_FAILURE: 'SOLD_TRADES_COUNT_FAILURE',

  FREE_TXNS_REQUEST: 'FREE_TXNS_REQUEST',
  FREE_TXNS_SUCCESS: 'FREE_TXNS_SUCCESS',
  FREE_TXNS_FAILURE: 'FREE_TXNS_FAILURE',
}
