import { CHAINS } from 'common/global-constants'
import { Network } from 'alchemy-sdk'

export const TOKENS = {
  WETH: 'weth',
  ETH: 'eth'
}

// must match values from TioSwap
export const NFT_TYPES = {
  ERC721: 1,
  ERC1155: 2
}

export const NFT_TYPE_NAMES = {
  [NFT_TYPES.ERC721]: 'Erc721',
  [NFT_TYPES.ERC1155]: 'Erc1155'
}

// TioSwap nftType -> dapp nftType
export const NFT_TYPE_MAP = {
  1: NFT_TYPES.ERC721,
  2: NFT_TYPES.ERC1155
}

// Alchemy API tokenType -> dapp nftType
// alchemy tokenType, only support ERC721 or ERC1155 currently
// alchemy tokenType = UNKNOWN -> not ERC721, ERC1155
export const ALCHEMY_NFT_TYPE_MAP = {
  'ERC721': NFT_TYPES.ERC721,
  'ERC1155': NFT_TYPES.ERC1155
}

export const ERC_INTERFACE_IDS = {
  [NFT_TYPES.ERC721]: '0x80ac58cd',
  [NFT_TYPES.ERC1155]: '0xd9b67a26',
}

export const EXPLORER_URL = {
  [CHAINS.MAINNET]: {
    TRANSACTION: 'https://etherscan.io/tx/',
    ACCOUNT: 'https://etherscan.io/address/',
    TOKEN: 'https://etherscan.io/token/',
    NFT: 'https://etherscan.io/nft/'
  },
  [CHAINS.GOERLI]: {
    TRANSACTION: 'https://goerli.etherscan.io/tx/',
    ACCOUNT: 'https://goerli.etherscan.io/address/',
    TOKEN: 'https://goerli.etherscan.io/token/',
    NFT: 'https://goerli.etherscan.io/nft/'
  },
  [CHAINS.SEPOLIA]: {
    TRANSACTION: 'https://sepolia.etherscan.io/tx/',
    ACCOUNT: 'https://sepolia.etherscan.io/address/',
    TOKEN: 'https://sepolia.etherscan.io/token/',
    NFT: 'https://sepolia.etherscan.io/nft/'
  },
  [CHAINS.GANACHE]: {
    TRANSACTION: 'https://ganache.etherscan.io/tx/', // fake
    ACCOUNT: 'https://ganache.etherscan.io/address/', // fake
    TOKEN: 'https://ganache.etherscan.io/token/', //fake
    NFT: 'https://ganache.etherscan.io/nft/' //fake
  }
}

export const ETHEREUM_RPC = {
  [CHAINS.MAINNET]: 'https://eth-mainnet.g.alchemy.com/v2/RsfYkClOT3mieWoAYPlmJ3DB2hoAlPda',
  [CHAINS.GOERLI]: 'https://eth-goerli.g.alchemy.com/v2/0uToby3nJL5YR9D-hTEjrugcXkDDrRKF',
  [CHAINS.SEPOLIA]: 'https://eth-sepolia.g.alchemy.com/v2/OK-_7lJdpmw6PBp0IEhbXpT4OaEp9QX2',
  [CHAINS.GANACHE]: 'http://127.0.0.1:8545',
}

export const ETHEREUM_WEBSOCKETS = {
  [CHAINS.MAINNET]: 'wss://eth-mainnet.g.alchemy.com/v2/RsfYkClOT3mieWoAYPlmJ3DB2hoAlPda',
  [CHAINS.GOERLI]: 'wss://eth-goerli.g.alchemy.com/v2/0uToby3nJL5YR9D-hTEjrugcXkDDrRKF',
  [CHAINS.SEPOLIA]: 'wss://eth-sepolia.g.alchemy.com/v2/OK-_7lJdpmw6PBp0IEhbXpT4OaEp9QX2',
  [CHAINS.GANACHE]: 'ws://127.0.0.1:8545',
}

export const ALCHEMY_KEYS = {
  [CHAINS.MAINNET]: 'RsfYkClOT3mieWoAYPlmJ3DB2hoAlPda',
  [CHAINS.GOERLI]: '0uToby3nJL5YR9D-hTEjrugcXkDDrRKF',
  [CHAINS.SEPOLIA]: 'OK-_7lJdpmw6PBp0IEhbXpT4OaEp9QX2',
  [CHAINS.GANACHE]: '',
}

export const ALCHEMY_NETWORKS = {
  [CHAINS.MAINNET]: Network.ETH_MAINNET,
  [CHAINS.GOERLI]: Network.ETH_GOERLI,
  [CHAINS.SEPOLIA]: Network.ETH_SEPOLIA,
  [CHAINS.GANACHE]: '',
}

export const GRAPHQL_URL = {
  [CHAINS.MAINNET]: 'https://api.studio.thegraph.com/query/46793/tioswap-mainnet/v1.0',
  [CHAINS.GOERLI]: 'https://api.studio.thegraph.com/query/46793/tioswap-goerli/v1.0',
  [CHAINS.SEPOLIA]: 'https://api.studio.thegraph.com/query/46793/tioswap-sepolia/v1.0',
  [CHAINS.GANACHE]: 'http://127.0.0.1:8000/subgraphs/name/tuantriumph/nfttrading',
}

export const WETH_ADDRESS = {
  [CHAINS.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [CHAINS.GOERLI]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
  [CHAINS.SEPOLIA]: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
  [CHAINS.GANACHE]: process?.env?.REACT_APP_DEV_WETH_ADDRESS,
}

export const TIOSWAP_ADDRESS = {
  [CHAINS.MAINNET]: '0x6413EadbA07EE868EeC62095a740E77162F8D735',
  [CHAINS.GOERLI]: '0xCa5b71Fe36D0B37185719b528ae31677c6A1Ca43',
  [CHAINS.SEPOLIA]: '0x4c414781Af476A0dAF64e6098d577b669bef711b',
  [CHAINS.GANACHE]: process?.env?.REACT_APP_DEV_TIOSWAP_ADDRESS,
}

// default
export const GAS_TOKEN = TOKENS.ETH
export const PAYMENT_TOKEN = TOKENS.WETH
export const MAX_PRICE = '100000000000000'

export const CONTRACT_STATUS = {
  WORKING: 0,
  PAUSED: 1
}

// 0x0 address
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const TRADE_EVENT = {
  NEW_TRADE_CREATED: 'LogNewTradeCreated',
  TRADE_CANCELLED: 'LogTradeStatusChanged',
  TRADE_COMPLETED: 'LogTradeStatusChanged',
  TRADE_PRICE_CHANGED: 'LogTradePriceChanged',
  TRADE_BUYER_CHANGED: 'LogTradeBuyerChanged',
}

export const TRADE_STATUS = {
  LISTED: 1,
  COMPLETED: 2,
  CANCELLED: 3,
  // additional status
  SOLD: 4,
  BOUGHT: 5,
}

// trade param useFreeTxn option yes, must match with TioSwap
export const FREE_TXN_OPTION_YES = 1

export const TRADE_STATUS_LABEL = {
  [TRADE_STATUS.LISTED]: 'Waiting for buyer',
  [TRADE_STATUS.CANCELLED]: 'Seller delisted',
  [TRADE_STATUS.COMPLETED]: 'Completed',
  [TRADE_STATUS.SOLD]: 'Sold',
  [TRADE_STATUS.BOUGHT]: 'Bought',
}

export const TRADE_STATUS_COLOR = {
  [TRADE_STATUS.LISTED]: '#00b8ce',
  [TRADE_STATUS.CANCELLED]: '#ff5341',
  [TRADE_STATUS.COMPLETED]: '#129092',
  [TRADE_STATUS.SOLD]: '#129092',
  [TRADE_STATUS.BOUGHT]: '#129092',
}

// lists returned by graphQl
export const GRAPHQL_LISTS = {
  LISTED: 'listed',
  CANCELLED: 'cancelled',
  SOLD: 'sold',
  BOUGHT: 'bought',
  FOR_YOU: 'foryou',
}

// public lists returned by graphQl
export const GRAPHQL_RECENT_LISTS = {
  ALL: 'all',
  LISTED: 'listed',
  SOLD: 'sold',
}

// map trade event with graphQl txns key
// must match with subgraph config
export const GRAPHQL_TXN_KEY = {
  LISTED: 'List',
  CANCELLED: 'Cancel',
  COMPLETED: 'Sale',
  RELISTED: 'Relist',
  PRICE_CHANGED: 'Price changed',
  BUYER_CHANGED: 'Buyer changed',
  WETH_APPROVAL: 'WETH Approval',
}

// display graph txn name
export const GRAPHQL_TXN_DISPLAY = {
  [GRAPHQL_TXN_KEY.LISTED]: 'Create',
  [GRAPHQL_TXN_KEY.CANCELLED]: 'Delist',
  [GRAPHQL_TXN_KEY.COMPLETED]: 'Complete',
  [GRAPHQL_TXN_KEY.RELISTED]: 'Relist',
  [GRAPHQL_TXN_KEY.PRICE_CHANGED]: 'Price changed',
  [GRAPHQL_TXN_KEY.BUYER_CHANGED]: 'Buyer changed',
  [GRAPHQL_TXN_KEY.WETH_APPROVAL]: 'WETH Approval',
}

// graph txn tip
export const GRAPHQL_TXN_TIPS = {
  [GRAPHQL_TXN_KEY.LISTED]: null,
  [GRAPHQL_TXN_KEY.CANCELLED]: null,
  [GRAPHQL_TXN_KEY.COMPLETED]: null,
  [GRAPHQL_TXN_KEY.RELISTED]: null,
  [GRAPHQL_TXN_KEY.PRICE_CHANGED]: null,
  [GRAPHQL_TXN_KEY.BUYER_CHANGED]: null,
  [GRAPHQL_TXN_KEY.WETH_APPROVAL]: 'Tip: Higher spend limit can prevent signing this again & save gas fee.',
}

// maximum trades each list in 1 query
export const GRAPHQL_LIST_LIMIT = process?.env?.REACT_APP_GRAPHQL_LIST_LIMIT

// max attempts
export const GRAPHQL_MAX_RETRIES = process?.env?.REACT_APP_GRAPHQL_MAX_RETRIES
export const GRAPHQL_MAX_TIMEOUT = process?.env?.REACT_APP_GRAPHQL_MAX_TIMEOUT

// alchemy retry
export const RPC_MAX_RETRIES = process?.env?.REACT_APP_RPC_MAX_RETRIES
export const RPC_MAX_TIMEOUT = process?.env?.REACT_APP_RPC_MAX_TIMEOUT

// assets tracking, only track nfts traded via TioSwap
export const DISABLE_ASSETS_TRACKING = process?.env?.REACT_APP_DISABLE_ASSETS_TRACKING

// allow zero or low price trade
export const ZERO_PRICE_ALLOWANCE = process?.env?.REACT_APP_ZERO_PRICE_ALLOWANCE

// maximum contact info length
export const MAX_CONTACT_INFO_LENGTH = process?.env?.REACT_APP_MAX_CONTACT_INFO_LENGTH

// development contracts
export const DEV_ERC721_CONTRACTS = process?.env?.REACT_APP_DEV_ERC721_CONTRACTS?.split(',')
export const DEV_ERC1155_CONTRACTS = process?.env?.REACT_APP_DEV_ERC1155_CONTRACTS?.split(',')
export const DEV_ERC1155_MAX_TOKENS = 10
