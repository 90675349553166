import { CONTRACT_STATUS } from 'EthereumApp/app-constants'
import { storeItem, loadStoredItem, clearStoredItem } from 'common/utils/storage'
import { appConstants } from '../constants'

const BigNumber = require('bignumber.js')

const initialState = {
  redirectURL: '',
  isProcessing: null,
  walletChainId: null,
  appChainId: null,

  pausedContract: loadStoredItem('@pausedContract'), // is smartcontract is not paused?
  feeRate: loadStoredItem('@feeRate'), // trade fee rate
  minFee: loadStoredItem('@minFee'), // trade minimum fee
  minPrice: loadStoredItem('@minPrice'), // minimum acceptable price
  referralConfig: loadStoredItem('@referralConfig'), // referral scheme

  loadingContractStatus: null,
  contractStatusError: null,
  loadingFee: null,
  loadingReferral: null,
  feeError: null,
  referralError: null,

  loadingWalletChain: null,
  errorWalletChain: null,
}

export function app(state = initialState, action) {
  switch (action.type) {
    case appConstants.SET_REDIRECT_URL:
      return {
        ...state,
        redirectURL: action.data,
      }

    case appConstants.SET_IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      }

    case appConstants.CONTRACT_STATUS_REQUEST:
      return {
        ...state,
        loadingContractStatus: true,
      }

    case appConstants.CONTRACT_STATUS_SUCCESS: {
      const isPausedContract = parseInt(action.status) === CONTRACT_STATUS.PAUSED
      storeItem('@pausedContract', isPausedContract)

      return {
        ...state,
        loadingContractStatus: false,
        pausedContract: isPausedContract
      }
    }

    case appConstants.CONTRACT_STATUS_FAILURE: {
      return {
        ...state,
        loadingContractStatus: false,
        pausedContract: null, // contract status is not determined
        contractStatusError: action.error,
      }
    }

    case appConstants.FEE_REQUEST:
      return {
        ...state,
        loadingFee: true,
      }

    case appConstants.FEE_SUCCESS: {
      const portion = new BigNumber(action.feeInfo.feePortion)
      const base = new BigNumber(action.feeInfo.feeBase)
      const minFee = new BigNumber(action.feeInfo.minFee)

      // convert all BN to BigNumber
      const rate = portion.dividedBy(base).toNumber()
      const minPrice = minFee.dividedBy(rate).dividedBy(1e+18).toNumber() // in Ether
      const _minFee = minFee.dividedBy(1e+18).toNumber() // in Ether

      storeItem('@feeRate', rate)
      storeItem('@minPrice', minPrice)
      storeItem('@minFee', _minFee)

      return {
        ...state,
        loadingFee: false,
        feeRate: rate,
        minFee: _minFee,
        minPrice: minPrice,
      }
    }

    case appConstants.FEE_FAILURE: {
      return {
        ...state,
        loadingFee: false,
        feeRate: null, // unknown fee info
        minFee: null,
        minPrice: null,
        feeError: action.error,
      }
    }

    case appConstants.REFERRAL_REQUEST:
      return {
        ...state,
        loadingReferral: true,
      }

    case appConstants.REFERRAL_SUCCESS: {
      const storedConfig = {
        maxFeeDiscount: action.referralConfig.maxFeeDiscount.toString(),
        maxFreeRef: action.referralConfig.maxFreeRef,
        freeTxnsForInviter: action.referralConfig.freeTxnsForInviter
      }
      storeItem('@referralConfig', storedConfig)

      return {
        ...state,
        referralConfig: storedConfig,
        loadingReferral: false,
      }
    }

    case appConstants.REFERRAL_FAILURE: {
      return {
        ...state,
        loadingReferral: false,
        referralConfig: null,
        referralError: action.error,
      }
    }

    case appConstants.WALLET_CHAIN_REQUEST: {
      return {
        ...state,
        loadingWalletChain: true,
        errorWalletChain: null,
      }
    }

    case appConstants.WALLET_CHAIN_SUCCESS: {
      return {
        ...state,
        walletChainId: parseInt(action.chainId),
        appChainId: parseInt(action.currentChainId),
        loadingWalletChain: true,
        errorWalletChain: null,
      }
    }

    case appConstants.WALLET_CHAIN_FAILURE: {
      return {
        ...state,
        walletChainId: null,
        loadingWalletChain: null,
        errorWalletChain: action.error,
      }
    }

    // reset all stored contract info
    case appConstants.CONNECT_RESET: {
      clearStoredItem('@pausedContract')
      clearStoredItem('@feeRate')
      clearStoredItem('@minFee')
      clearStoredItem('@minPrice')
      clearStoredItem('@referralConfig')

      const nullState = {...initialState}
      Object.keys(nullState).forEach(k => nullState[k] = null)

      return nullState
    }

    default:
      return state
  }
}
