import { userConstants } from '../constants'
import { storeItem, loadStoredItem, clearStoredItem, isAddress } from 'common/utils'
//import { nullAddress } from 'EthereumApp/utils'
//const BigNumber = require('bignumber.js')

const initialState = {
  account: loadStoredItem('@account'),
  referrer: loadStoredItem('@referrer'), // referrer stored on TioSwap
  referrerParam: loadStoredItem('@referrerParam'), // passed in referrer param
  invitesCount: loadStoredItem('@invitesCount'),
  tradesCount: loadStoredItem('@tradesCount'),
  soldTradesCount: loadStoredItem('@soldTradesCount'),
  freeTxns: loadStoredItem('@freeTxns'),

  loadingReferrer: null,
  loadingInvitesCount: null,
  loadingTradesCount: null,
  loadingSoldTradesCount: null,
  loadingFreeTxns: null
}

export function user(state = initialState, action) {
  switch (action.type) {
    // account is connected
    case userConstants.CONNECTED:
      const userAccount = action.address
      storeItem('@account', userAccount)

      return {
        ...state,
        account: userAccount,
      }

    // clear old account
    case userConstants.CONNECT_RESET: {
      clearStoredItem('@account')
      clearStoredItem('@referrer')
      clearStoredItem('@referrerParam')
      clearStoredItem('@invitesCount')
      clearStoredItem('@tradesCount')
      clearStoredItem('@soldTradesCount')
      clearStoredItem('@freeTxns')

      const nullState = {...initialState}
      Object.keys(nullState).forEach(k => nullState[k] = null)

      return nullState
    }

    // user referrer
    case userConstants.REFERRER_REQUEST:
      return {
        ...state,
        loadingReferrer: true,
      }

    case userConstants.REFERRER_SUCCESS: {
      storeItem('@referrer', action.referrer)

      return {
        ...state,
        loadingReferrer: false,
        referrer: action.referrer,
      }
    }

    case userConstants.REFERRER_FAILURE: {
      storeItem('@referrer', null)

      return {
        ...state,
        loadingReferrer: false,
        referrer: null
      }
    }

    case userConstants.STORE_REFERRER_PARAM: {
      const referrerParam = isAddress(action.referrerParam) ? action.referrerParam : null
      storeItem('@referrerParam', referrerParam)

      return {
        ...state,
        referrerParam: referrerParam,
      }
    }

    // user invites count
    case userConstants.INVITES_COUNT_REQUEST:
      return {
        ...state,
        loadingInvitesCount: true,
      }

    case userConstants.INVITES_COUNT_SUCCESS: {
      storeItem('@invitesCount', action.invitesCount)

      return {
        ...state,
        loadingInvitesCount: false,
        invitesCount: action.invitesCount,
      }
    }

    case userConstants.INVITES_COUNT_FAILURE: {
      storeItem('@invitesCount', null)

      return {
        ...state,
        loadingInvitesCount: false,
        invitesCount: null
      }
    }

    // user total trades count
    case userConstants.TOTAL_TRADES_COUNT_REQUEST:
      return {
        ...state,
        loadingTradesCount: true,
      }

    case userConstants.TOTAL_TRADES_COUNT_SUCCESS: {
      storeItem('@tradesCount', action.tradesCount)

      return {
        ...state,
        loadingTradesCount: false,
        tradesCount: action.tradesCount,
      }
    }

    case userConstants.TOTAL_TRADES_COUNT_FAILURE: {
      storeItem('@tradesCount', null)

      return {
        ...state,
        loadingTradesCount: false,
        tradesCount: null
      }
    }

    // user sold trades count
    case userConstants.SOLD_TRADES_COUNT_REQUEST:
      return {
        ...state,
        loadingSoldTradesCount: true,
      }

    case userConstants.SOLD_TRADES_COUNT_SUCCESS: {
      storeItem('@soldTradesCount', action.soldTradesCount)

      return {
        ...state,
        loadingSoldTradesCount: false,
        soldTradesCount: action.soldTradesCount,
      }
    }

    case userConstants.SOLD_TRADES_COUNT_FAILURE: {
      storeItem('@soldTradesCount', null)

      return {
        ...state,
        loadingSoldTradesCount: false,
        soldTradesCount: null
      }
    }

    // user free Txns count
    case userConstants.FREE_TXNS_REQUEST:
      return {
        ...state,
        loadingFreeTxns: true,
      }

    case userConstants.FREE_TXNS_SUCCESS: {
      storeItem('@freeTxns', action.freeTxns)

      return {
        ...state,
        loadingFreeTxns: false,
        freeTxns: action.freeTxns,
      }
    }

    case userConstants.FREE_TXNS_FAILURE: {
      storeItem('@freeTxns', null)

      return {
        ...state,
        loadingFreeTxns: false,
        freeTxns: null
      }
    }

    default:
      return state
  }
}
