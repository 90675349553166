import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import { DEBUGGING_MODE } from 'common/global-constants'

const middlewares = [thunkMiddleware]
if (DEBUGGING_MODE){
  const logger = createLogger()
  middlewares.push(logger)
}

export const ethereumAppStore = createStore(rootReducer, applyMiddleware(...middlewares))
