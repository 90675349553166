import { isChainReceipt, sameAddresses } from 'common/utils'
import { DEBUGGING_MODE } from 'common/global-constants'
import { TRADE_STATUS, TRADE_EVENT } from 'EthereumApp/app-constants'
const BigNumber = require('bignumber.js')

const verifyTradeEventReceipt = (receipt, eventName, newStatus) => {
	if (!isChainReceipt(receipt)) return null

	const log = receipt?.logs ? receipt.logs.find(l => l.event === eventName) : null

	// is trade's status matched?
	if (receipt.status === true && parseInt(log?.args?.newStatus) === parseInt(newStatus))
		return true

	return null
}

const verifyTradeEventReceiptNoStatus = (receipt, eventName) => {
	if (!isChainReceipt(receipt)) return null

	const log = receipt?.logs ? receipt.logs.find(l => l.event === eventName) : null

	// found such log?
	if (log)
		return true

	return null
}

export const isCreateReceipt = (receipt) => {
	return verifyTradeEventReceipt(receipt, TRADE_EVENT.NEW_TRADE_CREATED, TRADE_STATUS.LISTED)
}

export const isCancelReceipt = (receipt) => {
	return verifyTradeEventReceipt(receipt, TRADE_EVENT.TRADE_CANCELLED, TRADE_STATUS.CANCELLED)
}

export const isPriceChangedReceipt = (receipt) => {
	return verifyTradeEventReceiptNoStatus(receipt, TRADE_EVENT.TRADE_PRICE_CHANGED)
}

export const isBuyerChangedReceipt = (receipt) => {
	return verifyTradeEventReceiptNoStatus(receipt, TRADE_EVENT.TRADE_BUYER_CHANGED)
}

export const isCompleteReceipt = (receipt) => {
	return verifyTradeEventReceipt(receipt, TRADE_EVENT.TRADE_COMPLETED, TRADE_STATUS.COMPLETED)
}

export const isSuccessReceipt = (receipt) => {
	return isChainReceipt(receipt) && receipt.status === true
}

export const isWethApprove = (receipt, from, to, amount) => {
	if (!isSuccessReceipt(receipt)) return false

	const log = receipt?.logs ? receipt.logs.find(l => l.event === 'Approval') : null
	const _amount = new BigNumber(amount)
	const value = new BigNumber(log?.args?.wad)
	const owner = log?.args?.src
	const spender = log?.args?.guy
	DEBUGGING_MODE && console.log('isWethApprove:', owner, spender, value.toFixed(), _amount.toFixed())

	return (sameAddresses(owner, from) && sameAddresses(spender, to) && value.isGreaterThanOrEqualTo(_amount))
}
