export const assetsConstants = {
  BALANCE_REQUEST: 'BALANCE_GET_REQUEST',
  BALANCE_SUCCESS: 'BALANCE_GET_SUCCESS',
  BALANCE_FAILURE: 'BALANCE_GET_FAILURE',
  BALANCE_REMOVE: 'BALANCE_REMOVE',

  NFTS_ONE_CONTRACT_REQUEST: 'LOAD_NFTS_FROM_ONE_CONTRACT_REQUEST',
  NFTS_SAME_CONTRACT_SUCCESS: 'LOAD_NFTS_FROM_ONE_CONTRACT_SUCCESS',
  NFTS_SAME_CONTRACT_FAILURE: 'LOAD_NFTS_FROM_ONE_CONTRACT_FAILURE',

  ALL_NFTS_REQUEST: 'LOAD_ALL_NFTS_REQUEST',
  ALL_NFTS_SUCCESS: 'LOAD_ALL_NFTS_SUCCESS',
  ALL_NFTS_FAILURE: 'LOAD_ALL_NFTS_FAILURE',

  COLLECTION_INFO_REQUEST: 'COLLECTION_INFO_REQUEST',
  COLLECTION_INFO_SUCCESS: 'COLLECTION_INFO_SUCCESS',
  COLLECTION_INFO_FAILURE: 'COLLECTION_INFO_FAILURE',

  NFT_INFO_REQUEST: 'NFT_INFO_REQUEST',
  NFT_INFO_SUCCESS: 'NFT_INFO_SUCCESS',
  NFT_INFO_FAILURE: 'NFT_INFO_FAILURE',

  ADD_NFTS: 'ADD_NFTS',
  REMOVE_NFTS: 'REMOVE_NFTS',

  CONNECT_RESET: 'ASSETS_RESET_ON_WALLET_CONNECTED',
}
