import { CHAINS, DEBUGGING_MODE } from 'common/global-constants'
import { ETHEREUM_RPC, ETHEREUM_WEBSOCKETS, GRAPHQL_URL, ALCHEMY_KEYS, ALCHEMY_NETWORKS,
				 WETH_ADDRESS, TIOSWAP_ADDRESS } from 'EthereumApp/app-constants'
import { getCurrentChain } from 'common/utils'
import { Alchemy } from 'alchemy-sdk'

const Web3 = require('web3')
const Web3HttpProvider = require('web3-providers-http')
const Web3WsProvider = require('web3-providers-ws')
const TruffleContract = require('@truffle/contract')

const TioSwapJson = require('./contract-abis/TioSwap.json')
const Erc721Json = require('./contract-abis/ERC721Enumerable.json')
const Erc1155Json = require('./contract-abis/ERC1155.json')
const WethJson = require('./contract-abis/WETH9.json')

//const { sleep, delay } = require('utils')

const options = {
  keepAlive: false,
  withCredentials: false,
  //timeout: 30000, // ms - seems not work?
/*
  headers: [
		{
	    name: 'Access-Control-Allow-Origin',
	    value: '*'
		}
	]
*/
	// Enable auto reconnection
  reconnect: {
      auto: true,
      delay: 5000, // ms
      maxAttempts: 5,
      onTimeout: false
  }
}

const currentChain = getCurrentChain()
DEBUGGING_MODE && console.log('ChainTalk currentChain:', currentChain)

// graphQl
const { GraphQLClient } = require('graphql-request')
const graphqlClient = GRAPHQL_URL[currentChain] ?
	new GraphQLClient(GRAPHQL_URL[currentChain], { headers: {} }) : null

// Alchemy
// Optional Config object, but defaults to demo api-key and eth-mainnet.
const alchemySettings = {
  apiKey: ALCHEMY_KEYS[currentChain],
  network: ALCHEMY_NETWORKS[currentChain],
};
const alchemyClient = new Alchemy(alchemySettings)

// http provider
const HttpProvider = ETHEREUM_RPC[currentChain] ?
	new Web3HttpProvider(ETHEREUM_RPC[currentChain], options) : null
// websocket provider
const WebsocketProvider = ETHEREUM_WEBSOCKETS[currentChain] ?
	new Web3WsProvider(ETHEREUM_WEBSOCKETS[currentChain], options) : null
// web3
const web3 = HttpProvider ?
	new Web3(HttpProvider) : null

const ChainTalk = {
	onLocalChain: () => {
		return currentChain === CHAINS.GANACHE
	},

	getBlock: (hash) => {
		try {
			return web3.eth.getBlock(hash)
		} catch(e) {
			return Promise.reject(e)
		}
	},

	getTxCount: (address) => {
		try {
			return web3.eth.getTransactionCount(address)
		} catch(e) {
			return Promise.reject(e)
		}
	},

	getReceipt: (txHash) => {
		try {
			console.log('ChainTalk fetching receipt...', txHash)
		  return web3.eth.getTransactionReceipt(txHash)
		} catch(e) {
			return Promise.reject(e)
		}
	},

	getBalance: (address) => {
		try {
			console.log('ChainTalk fetching ETH balance of', address, '...')
			return web3.eth.getBalance(address)
		} catch(e) {
			return Promise.reject(e)
		}
	},

	call: (func) => {
		try {
			const _f = {...func}
			_f._parent.setProvider(HttpProvider)
			return _f.call()
		} catch(e) {
			return Promise.reject(e)
		}
	},

	// send raw transaction
	rawSend: async (rawTx) => {
		try {
			const combinedReceipt = {}
			console.log('ChainTalk sending rawTx...', rawTx)
			return web3.eth.sendSignedTransaction(rawTx)
				.once('sending', (payload) => {
					DEBUGGING_MODE && console.log('ChainTalk rawTx - before sending:', payload)
				})
				.once('sent', (payload) => {
					combinedReceipt.sentToClient = true
					DEBUGGING_MODE && console.log('ChainTalk rawTx - sent to client:', payload)
				})
				.once('transactionHash', (txHash) => {
					combinedReceipt.receivedTxHash = txHash
					DEBUGGING_MODE && console.log('ChainTalk rawTx - txHash:', txHash)
				})
				// web3 will keep calling eth_getTransactionReceipt until receipt is received
				.once('receipt', (receipt) => {
					Object.assign(combinedReceipt, receipt)
					DEBUGGING_MODE && console.log('ChainTalk rawTx - receipt:', receipt)
				})
				// If a out of gas error, the second parameter is the receipt.
				.on('error', (error) => {
					DEBUGGING_MODE && console.log('ChainTalk rawTx - error event:', error)
				})
				// will be fired once the receipt is mined
				.then((receipt) => {
					combinedReceipt.minedReceipt = true
					DEBUGGING_MODE && console.log('ChainTalk rawTx - mined receipt:', receipt)

					return Promise.resolve(combinedReceipt)
				}, (error) => {
					//Error: Returned error: nonce too low => need to rebuild the rawTx
					console.log('ChainTalk rawTx - error:', error)

					return Promise.reject(Object.assign(error, combinedReceipt))
				})

		} catch(e) {
			console.log('ChainTalk rawSend exception:', e)
			return Promise.reject(e)
		}
	},

	// by truffle contract
	TioSwap: (provider, ws = false) => {
		const contract = TruffleContract({
			abi: TioSwapJson.abi,
		})
		contract.setProvider(provider ? provider : (ws ? WebsocketProvider : HttpProvider))

		return contract.at(TIOSWAP_ADDRESS[currentChain])
	},

	WethContract: (provider, ws = false) => {
		const contract = TruffleContract({
			abi: WethJson.abi,
		})
		contract.setProvider(provider ? provider : (ws ? WebsocketProvider : HttpProvider))

		return contract.at(WETH_ADDRESS[currentChain])
	},

	Erc721Contract: (contractAddress, provider, ws = false) => {
		// web3
		//const contract = new web3.eth.Contract(Erc721Json.abi, contractAddress)

		// truffle contract
		const contract = TruffleContract({
			abi: Erc721Json.abi,
		})
		contract.setProvider(provider ? provider : (ws ? WebsocketProvider : HttpProvider))

		return contract.at(contractAddress)
	},

	Erc1155Contract: (contractAddress, provider, ws = false) => {
		// web3
		//const contract = new web3.eth.Contract(Erc721Json.abi, contractAddress)

		// truffle contract
		const contract = TruffleContract({
			abi: Erc1155Json.abi,
		})
		contract.setProvider(provider ? provider : (ws ? WebsocketProvider : HttpProvider))

		return contract.at(contractAddress)
	},

	// graphQl client
	graphqlClient: () => {
		return graphqlClient
	},

	alchemyClient: () => {
		return alchemyClient
	},

	loadProvider: (ws = false) => {
		return ws ? WebsocketProvider : HttpProvider
	},
}

export default ChainTalk
