export const CHAINS = {
	MAINNET: 'ethereum',
	GOERLI: 'goerli',
	SEPOLIA: 'sepolia',
	RONIN: 'roninchain',
	GANACHE: 'ganache'
}

// for network selector
export const CHAIN_NAMES = {
	[CHAINS.MAINNET]: 'Ethereum Mainnet',
	[CHAINS.GOERLI]: 'Goerli Testnet',
	[CHAINS.SEPOLIA]: 'Sepolia Testnet',
	[CHAINS.RONIN]: 'Ronin Sidechain',
	[CHAINS.GANACHE]: 'Local Ganache'
}

export const CHAIN_IDS = {
	[CHAINS.MAINNET]: 0x1,
	[CHAINS.GOERLI]: 0x5,
	[CHAINS.SEPOLIA]: 0xaa36a7,
	[CHAINS.RONIN]: 0x7e4,
	[CHAINS.GANACHE]: 0x539
}

export const CHAIN_IDS_MAPPING = {
	0x1: CHAINS.MAINNET,
	0x5: CHAINS.GOERLI,
	0xaa36a7: CHAINS.SEPOLIA,
	0x7e4: CHAINS.RONIN,
	0x539: CHAINS.GANACHE,
}

// the first chain is the default one
export const ENABLED_CHAINS = process.env.REACT_APP_ENABLED_CHAINS?.split(',')?.map(s => parseInt(s))
export const DEFAULT_CHAIN = CHAIN_IDS_MAPPING[ENABLED_CHAINS[0]]

// more logs info
export const DEBUGGING_MODE = process?.env?.REACT_APP_DEBUGGING_MODE
