import { NFT_TYPE_NAMES } from 'EthereumApp/app-constants'
import { isChainReceipt, truncateAddress } from 'common/utils'
import defaultNftIcon from 'common/assets/images/nft-icon.jpeg'
//const _ = require('underscore')
const BigNumber = require('bignumber.js')

export function NftCollection (address, nftType, tokenIds, balanceRef = {}, approved = undefined) {
	this.contractAddress = address.toLowerCase()
	this.tokenIds = tokenIds ? tokenIds.map(_id => _id.toString()) : []

	// must be Erc721 (1) or Erc1155 (2)
	this.nftType = nftType

	// balance reference
	// id => amount
	this.balanceRef = {}
	tokenIds.forEach(id => {
		this.balanceRef[id] = new BigNumber(balanceRef[id])
	})

	// approved can be true/false	or a receipt
	// - undefined : unknown
	// - null : is checking
	// - 0 : is requesting permission
	// - true/false
	// - receipt: -> receipt.status -> true|false
	this.approved = approved

	// actions

	this.nftKeyGen = (nftId) => {
		return genNftKey(this.contractAddress, nftId)
	}

	this.addNft = (id, amount) => {
		const _id = id.toString()
		if (!this.tokenIds.includes(_id))
			this.tokenIds.push(_id)

		if (!this.balanceRef?.[_id])
			this.balanceRef[_id] = new BigNumber(0)

		this.balanceRef[_id] = this.balanceRef[_id].plus(amount)
	}

	this.addNfts = (ids, amountRef) => {
		for(let i = 0; i < ids.length; i++) {
			this.addNft(ids[i], amountRef[ids[i]])
		}
	}

	this.removeNft = (id, amount) => {
		const _id = id.toString()
		const pos = this.tokenIds.indexOf(_id)

		if (pos >= 0) {
			if (this.balanceRef?.[_id] && !this.balanceRef[_id].isNaN())
				this.balanceRef[_id] = this.balanceRef[_id].minus(amount)

			// remove from tokenIds as the balance is 0 or null
			if (!this.balanceRef?.[_id] || this.balanceRef[_id] <= 0 || this.balanceRef[_id].isNaN()) {
				// remove balance
				delete this.balanceRef[_id]
				this.tokenIds.splice(pos, 1)
			}
		}
	}

	this.removeNfts = (ids, amountRef) => {
		for(let i = 0; i < ids.length; i++) {
			this.removeNft(ids[i], amountRef[ids[i]])
		}
	}

	this.updateAmount = (id, amount) => {
		const _id = id.toString()
		if (this.balanceRef?.[_id])
			this.balanceRef[_id] = new BigNumber(amount)
	}

	this.approvalCheck = () => {
		this.approved = null
	}

	this.approvalRequest = () => {
		this.approved = 0
	}

	this.nftsCount = () => {
		return this.tokenIds.length
	}

	// query

	this.isApproved = () => {
		return (
			this.approved === true ||	(isChainReceipt(this.approved) && this.approved.status === true)
		)
	}

	this.isNotApproved = () => {
		return (
			this.approved === false || (isChainReceipt(this.approved) && this.approved.status === false)
		)
	}

	this.approvalUnknown = () => {
		return this.approved === undefined
	}

	this.isChecking = () => {
		return this.approved === null
	}

	this.isRequesting = () => {
		return this.approved === 0
	}
}

// generate nft key
export const genNftKey = (contractAddress, tokenId) => {
	return `${contractAddress}-${tokenId}`
}

// load nfts stored in session store and parse it into NftCollection
export const loadInitCollection = (storedNfts) => {
	if (!storedNfts) return

  const parsedNfts = {}
  Object.keys(storedNfts).forEach(address => {
    parsedNfts[address] = new NftCollection(
      storedNfts[address].contractAddress,
      storedNfts[address].nftType,
      storedNfts[address].tokenIds,
      storedNfts[address].balanceRef
    )
  })

  return parsedNfts
}

// link of nft image
export const getSampleNftImage = () => {
	return defaultNftIcon
}

// display collection name using collectionRef
export const displayCollectionName = (collectionRef) => {
	if (!collectionRef)
		return

	if (collectionRef.name)
		return collectionRef.name

	if (collectionRef.symbol)
		return collectionRef.symbol

	return `${NFT_TYPE_NAMES[collectionRef.nftType]} (${truncateAddress(collectionRef.address)})`
}
