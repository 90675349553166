import { useRef, useEffect } from 'react'

/*
Hook to solve the issue when we change unmounted component state(s).

Error:
Warning: Can't perform a React state update on an unmounted component.
This is a no-op, but it indicates a memory leak in your application.
To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
*/

export function useIsMountedRef() {
	const isMountedRef = useRef(null)

	/*
	A useEffect without dependencies (or undefined as dependencies) will run at every render,
	but always as a side-effect. That means it runs after the component has rendered.
	*/
	useEffect(() => {
		// component is mounted
		isMountedRef.current = true

		return () => {
			// component is unmounted
			isMountedRef.current = false
		}
	})

	return isMountedRef
}
