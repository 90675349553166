import { EXPLORER_URL } from 'EthereumApp/app-constants'
import { nullAddress } from 'EthereumApp/utils'
import { getCurrentChain } from 'common/utils'

const currentChain = getCurrentChain()

export const getAccountUrl = (address) => {
  return nullAddress(address) ? null : (EXPLORER_URL[currentChain].ACCOUNT + address)
}

export const getTokenUrl = (address) => {
  return nullAddress(address) ? null : (EXPLORER_URL[currentChain].TOKEN + address)
}

export const getNftUrl = (address, tokenId) => {
  return nullAddress(address) ? null : (EXPLORER_URL[currentChain].NFT + address + '/' + tokenId)
}

export const getTxHashUrl = (txHash) => {
  return txHash ? (EXPLORER_URL[currentChain].TRANSACTION + txHash) : null
}
