import React from 'react'
import ReactDOM from 'react-dom'
import loadable from '@loadable/component'
import { Provider } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'common/assets/css/root.css'
import 'common/assets/css/utils.css'
import './index.css'
import reportWebVitals from './reportWebVitals'

import { ethereumAppStore } from './EthereumApp/redux/store'
import { getReactGA, getCurrentChain, storeItem, loadStoredItem, allStorageClear } from 'common/utils'
import { DEBUGGING_MODE } from 'common/global-constants'

const EthereumApp = loadable(() => import('./EthereumApp'))
const MaintenancePage = loadable(() => import('MaintenancePage'))

// check app version to clear all local & session stored items
const currentAppVersion = process?.env?.REACT_APP_VERSION
const storedAppVersion = loadStoredItem('APP_VERSION')
console.log('TioSwap version:', process?.env?.NODE_ENV, currentAppVersion, storedAppVersion)

parseInt(DEBUGGING_MODE) === 2 && console.log('TioSwap ENV:', process?.env)

if (currentAppVersion && currentAppVersion !== storedAppVersion) {
  DEBUGGING_MODE && console.log('Clearing all stored items on new deployment...')
  allStorageClear()
  storeItem('APP_VERSION', currentAppVersion)
}

const selectedChain = getCurrentChain()
DEBUGGING_MODE && console.log('On Blockchain:', selectedChain)

// Google Analytics
const GAinstance = getReactGA()
if (GAinstance.isInitialized)
  GAinstance.send({ hitType: 'pageview', page: window.location.pathname, title: `Page access, Chain: ${selectedChain}` })

ReactDOM.render(
  <React.StrictMode>
  { process?.env?.REACT_APP_MAINTENANCE_MODE ?
    <MaintenancePage />
    :
    <Provider store={ethereumAppStore}>
      <EthereumApp />
    </Provider>
  }
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
