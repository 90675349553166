import { combineReducers } from 'redux'
import { app } from './app.reducer'
import { assets } from './assets.reducer'
import { trades } from './trades.reducer'
import { user } from './user.reducer'

const rootReducer = combineReducers({
  app,
  assets,
  trades,
  user,
})

export default rootReducer
