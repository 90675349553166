import { storeItem, loadStoredItem, clearStoredItem } from 'common/utils/storage'
import { NftCollection, loadInitCollection, buildCollectionBalanceRef } from 'EthereumApp/utils'
import { NFT_TYPE_MAP } from 'EthereumApp/app-constants'
import { assetsConstants } from '../constants'
const _ = require('underscore')

const initialState = {
  // onchain data
  balance: loadStoredItem('@balance'),
  // owned nfts (array of NftCollection)
  nfts: loadInitCollection(loadStoredItem('@nfts')),
  // nft contract metadata reference
  collectionRef: loadStoredItem('@collectionRef'),
  // nft metadata reference
  nftRef: loadStoredItem('@nftRef'),

  // initial user WETH & ETH balances loading
  loadingBalances: null,
  errorBalances: null,

  // initial all user nfts loading: null-false|true
  loadingAllNfts: null,
  errorNfts: null,

  // hash of being nfts-loaded collection addresses
  // { tradeId => true|false|undefined }
  loadingCollections: null,
  errorCollections: null,

  // array of being metadata-loaded collection addresses
  loadingCollectionInfos: null,
  errorCollectionInfos: null,

  // array of being metadata-loaded nft ids
  loadingNftRef: null,
  errorNftRef: null,
}

export function assets(state = initialState, action) {
  switch (action.type) {
    // request to load user balance
    case assetsConstants.BALANCE_REQUEST:
      return {
        ...state,
        loadingBalances: true,
      }

    // user balance are loaded
    case assetsConstants.BALANCE_SUCCESS: {
      const balance = state.balance ? {...state.balance} : {}
      balance[action.tokenName] = action.value
      storeItem('@balance', balance)

      return {
        ...state,
        loadingBalances: false,
        balance: balance,
      }
    }

    // fail to load user balance
    case assetsConstants.BALANCE_FAILURE: {
      const balance = state.balance ? {...state.balance} : {}
      // to be sure about user's balance, should return null balance
      balance[action.tokenName] = null
      storeItem('@balance', balance)

      return {
        ...state,
        loadingBalances: false,
        balance: balance,
        errorBalances: action.error,
      }
    }

    // remove stored balance
    case assetsConstants.BALANCE_REMOVE: {
      const balance = {}
      storeItem('@balance', balance)

      return {
        ...state,
        balance: balance,
      }
    }

    // request to load all nfts in 1 contract
    case assetsConstants.NFTS_ONE_CONTRACT_REQUEST: {
      return {
        ...state,
        loadingCollections: {...state.loadingCollections, [action.contractAddress]: true},
      }
    }

    // all nfts in 1 contract are loaded
    case assetsConstants.NFTS_SAME_CONTRACT_SUCCESS: {
      const nfts = state.nfts ? {...state.nfts} : {}
      const address = action.collection.contractAddress.toLowerCase()
      nfts[address] = action.collection

      storeItem('@nfts', nfts)
      return {
        ...state,
        loadingCollections: _.omit(state.loadingCollections, action.contractAddress),
        errorCollections: _.omit(state.errorCollections, action.contractAddress),
        nfts: nfts,
      }
    }

    // fail to load all nfts in 1 contract
    case assetsConstants.NFTS_SAME_CONTRACT_FAILURE: {
      return {
        ...state,
        loadingCollections: _.omit(state.loadingCollections, action.contractAddress),
        errorCollections: {...state.errorCollections, [action.contractAddress]: action.error},
      }
    }

    // request to load nfts
    case assetsConstants.ALL_NFTS_REQUEST: {
      return {
        ...state,
        loadingAllNfts: true,
      }
    }

    // all nfts loaded
    case assetsConstants.ALL_NFTS_SUCCESS: {
      // overwrite all existed stored collection
      const nfts = action.collections

      storeItem('@nfts', nfts)
      return {
        ...state,
        loadingAllNfts: false,
        nfts: nfts,
      }
    }

    // fail to load all Nfts
    case assetsConstants.ALL_NFTS_FAILURE: {
      return {
        ...state,
        loadingAllNfts: false,
        errorNfts: action.error,
      }
    }

    // add nfts in trade to ownNfts
    case assetsConstants.ADD_NFTS: {
      const nfts = state.nfts ? {...state.nfts} : {}

      action.trade.collection.forEach(c => {
        const address = c.contractAddress.toLowerCase()
        const amountRef = buildCollectionBalanceRef(c)

        if (!nfts[address])
          nfts[address] = new NftCollection(
            address,
            NFT_TYPE_MAP[c.nftType],
            []
          )

        nfts[address].addNfts(c.tokenIds, amountRef)
      })

      storeItem('@nfts', nfts)
      return {
        ...state,
        nfts: nfts,
      }
    }

    // remove nfts in trade from ownNfts
    case assetsConstants.REMOVE_NFTS: {
      const nfts = state.nfts ? {...state.nfts} : {}

      action.trade.collection.forEach(c => {
        const address = c.contractAddress.toLowerCase()
        const amountRef = buildCollectionBalanceRef(c)

        if (nfts[address])
          nfts[address].removeNfts(c.tokenIds, amountRef)
      })

      storeItem('@nfts', nfts)
      return {
        ...state,
        nfts: nfts,
      }
    }

    // erc721 contract info request
    // allow multiple contract loading
    case assetsConstants.COLLECTION_INFO_REQUEST:{
      const address = action.contractAddress.toLowerCase()

      return {
        ...state,
        loadingCollectionInfos: _.union(state.loadingCollectionInfos, [address]),
        errorCollectionInfos: _.without(state.errorCollectionInfos, address),
      }
    }

    // erc721 contract info is loaded
    case assetsConstants.COLLECTION_INFO_SUCCESS: {
      const collectionRef = state.collectionRef ? {...state.collectionRef} : {}
      const address = action.contractInfo.address.toLowerCase()
      collectionRef[address] = action.contractInfo
      storeItem('@collectionRef', collectionRef)

      return {
        ...state,
        collectionRef: collectionRef,
        loadingCollectionInfos: _.without(state.loadingCollectionInfos, address),
        errorCollectionInfos: _.without(state.errorCollectionInfos, address),
      }
    }

    // fail to load contract info
    case assetsConstants.COLLECTION_INFO_FAILURE: {
      const address = action.contractAddress.toLowerCase()

      return {
        ...state,
        loadingCollectionInfos: _.without(state.loadingCollectionInfos, address),
        errorCollectionInfos: _.union(state.errorCollectionInfos, [address]),
      }
    }

    //
    case assetsConstants.NFT_INFO_REQUEST: {
      const address = action.contractAddress.toLowerCase()
      const tokenId = action.tokenId.toString()
      const loadingNftRef = state.loadingNftRef ? {...state.loadingNftRef} : {}
      const errorNftRef = state.errorNftRef ? {...state.errorNftRef} : {}

      // add token to loading list & remove it from error list
      loadingNftRef[address] = _.union(loadingNftRef[address], [tokenId])
      errorNftRef[address] = _.without(errorNftRef[address], tokenId)

      return {
        ...state,
        loadingNftRef: loadingNftRef,
        errorNftRef: errorNftRef,
      }
    }

    case assetsConstants.NFT_INFO_SUCCESS: {
      const address = action.contractAddress.toLowerCase()
      const tokenId = action.tokenId.toString()
      const loadingNftRef = state.loadingNftRef ? {...state.loadingNftRef} : {}
      const errorNftRef = state.errorNftRef ? {...state.errorNftRef} : {}
      const nftRef = state.nftRef ? {...state.nftRef} : {}

      // remove token from loading list & error list
      loadingNftRef[address] = _.without(loadingNftRef[address], tokenId)
      errorNftRef[address] = _.without(errorNftRef[address], tokenId)

      // add nft metadata to stored list
      nftRef[address] = nftRef[address] ? {...nftRef[address]} : {}
      nftRef[address][tokenId] = action.metadata
      storeItem('@nftRef', nftRef)

      return {
        ...state,
        nftRef: nftRef,
        loadingNftRef: loadingNftRef,
        errorNftRef: errorNftRef,
      }
    }

    case assetsConstants.NFT_INFO_FAILURE: {
      const address = action.contractAddress.toLowerCase()
      const tokenId = action.tokenId.toString()
      const loadingNftRef = state.loadingNftRef ? {...state.loadingNftRef} : {}
      const errorNftRef = state.errorNftRef ? {...state.errorNftRef} : {}

      // add token to loading list & remove it from error list
      loadingNftRef[address] = _.without(loadingNftRef[address], tokenId)
      errorNftRef[address] = _.union(errorNftRef[address], [tokenId])

      return {
        ...state,
        loadingNftRef: loadingNftRef,
        errorNftRef: errorNftRef,
      }
    }

    // reset all stored assets when new account is connected
    case assetsConstants.CONNECT_RESET: {
      clearStoredItem('@balance')
      clearStoredItem('@nfts')
      clearStoredItem('@nftContractRef')
      clearStoredItem('@collectionRef')

      const nullState = {...initialState}
      Object.keys(nullState).forEach(k => nullState[k] = null)

      return nullState
    }

    //
    default:
      return state
  }
}
