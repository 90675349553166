export const tradesConstants = {
  GET_ONE_REQUEST: 'GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'GET_ONE_FAILURE',

  STORE_ONE_FROM_RECEIPT: 'STORE_ONE_FROM_RECEIPT',
  CLEAR_STORED_TRADE: 'CLEAR_STORED_TRADE',
  REMOVE_STORED_TRADE: 'REMOVE_STORED_TRADE',
  CLEAR_LOAD_TRADE_ERROR: 'CLEAR_LOAD_TRADE_ERROR',

  TRADE_NOTIFIED: 'TRADE_NOTIFIED',

  GET_LIST_REQUEST: 'GET_ONE_LIST_REQUEST',
  GET_LIST_SUCCESS: 'GET_ONE_LIST_SUCCESS',
  GET_LIST_FAILURE: 'GET_ONE_LIST_FAILURE',

  GET_LISTS_REQUEST: 'GET_ALL_TRADES_REQUEST',
  GET_LISTS_SUCCESS: 'GET_ALL_TRADES_SUCCESS',
  GET_LISTS_FAILURE: 'GET_ALL_TRADES_FAILURE',

  GET_RECENT_LIST_REQUEST: 'GET_RECENT_LIST_REQUEST',
  GET_RECENT_LIST_SUCCESS: 'GET_RECENT_LIST_SUCCESS',
  GET_RECENT_LIST_FAILURE: 'GET_RECENT_LIST_FAILURE',
  UPDATE_RECENT_LISTS_REQUEST: 'UPDATE_RECENT_LISTS_REQUEST',
  UPDATE_RECENT_LISTS: 'UPDATE_RECENT_LISTS',

  GET_RECENT_LISTS_REQUEST: 'GET_RECENT_TRADES_REQUEST',
  GET_RECENT_LISTS_SUCCESS: 'GET_RECENT_TRADES_SUCCESS',
  GET_RECENT_LISTS_FAILURE: 'GET_RECENT_TRADES_FAILURE',

  CONNECT_RESET: 'LISTS_RESET_ON_WALLET_CONNECTED',
}
